<template>
    <div
        class="product-container"
        :class="[
            layoutClassName,
            typeClassName
        ]"
        :style="{
            '--background-color': backgroundColor,
            '--text-color': textColor
        }">
        <overlay
            v-for="(overlay, index) in overlays"
            :key="index"
            :value="overlay"
            :ad-data="adData"
            :product="product"
            :light-background="lightBackground" />
        <div class="product">
            <div class="product-content">
                <img
                    v-if="(
                        layout !== LAYOUT_FILL_HEIGHT &&
                        layout !== LAYOUT_TILT
                    )"
                    class="product-image"
                    :src="primaryImage">
                <div class="product-details">
                    <span
                        v-if="title"
                        class="product-title">
                        {{ title }}
                    </span>
                    <span
                        v-if="subTitle"
                        class="product-sub-title">
                        {{ subTitle }}
                    </span>
                </div>
            </div>
            <div
                v-if="(
                    layout !== LAYOUT_FILL_HEIGHT &&
                    layout !== LAYOUT_TILT
                )"
                :style="{
                    backgroundColor
                }"
                class="background" />
            <img
                v-if="(
                    layout === LAYOUT_FIT ||
                    layout === LAYOUT_FILL_HEIGHT ||
                    layout === LAYOUT_TILT
                )"
                class="background-image"
                :src="primaryImage">
        </div>
    </div>
</template>

<script>
import { addAlpha, hexIsLight } from '@/helpers/colors';
import Overlay from './Overlay';

const LAYOUT_HEADER = 'HEADER'; // Content over image
const LAYOUT_FIT = 'FIT'; // Adds background image with color
const LAYOUT_FILL_HEIGHT = 'FILL_HEIGHT'; // Fills the page with image
const LAYOUT_TILT = 'TILT'; // Content on bottom over tilted block

export default {
    components: {
        Overlay
    },
    props: {
        adData: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            LAYOUT_FIT,
            LAYOUT_FILL_HEIGHT,
            LAYOUT_TILT,
            LAYOUT_HEADER
        };
    },
    computed: {
        primaryImage() {
            // @todo Add dynamic placeholder
            return this.product.primary_image;
        },
        textFields() {
            if (this.adData.overlay?.text_fields) {
                return this.adData.overlay.text_fields.map(field => {

                    // Custom fields are wrapped in `` so let's check
                    // for the pattern and extract the content
                    const regexResult = field.match(/`(.*)`/);
                    if (regexResult) {
                        return regexResult[1];
                    }

                    // From here on we need to ensure the product
                    // has the field
                    if (!this.product[field]) {
                        return '';
                    }

                    if (
                        field === 'price' ||
                        field === 'salePrice'
                    ) {
                        return this.$formatNumber(
                            this.product[field],
                            this.$numberFormats.currency
                        );
                    }

                    return this.capitalizeFirstLetter(this.product[field]);
                });
            }

            return [];
        },
        title() {
            return this.textFields[0] ?? '';
        },
        subTitle() {
            return this.textFields[1] ?? '';
        },
        layout() {
            return this.adData?.overlay?.layout;
        },
        layoutClassName() {
            return this.layout ? `layout__${this.layout.toLowerCase()}` : '';
        },
        type() {
            return this.adData?.creatives?.[0]?.media_type || this.adData?.media_items?.[0]?.media_type;
        },
        typeClassName() {
            return this.type ? `type__${this.type.toLowerCase()}` : '';
        },
        lightBackground() {
            if (this.backgroundColor === 'transparent') {
                return false;
            }

            return hexIsLight(this.backgroundColor);
        },
        textColor() {
            return this.lightBackground ? '#000' : '#fff';
        },
        backgroundColor() {
            const color = this.adData?.overlay?.background_color;

            // FIT - Sets color overlay over background image
            // FILL_HEIGHT - Adds color background behind text
            if (
                this.layout === LAYOUT_FIT ||
                this.layout === LAYOUT_FILL_HEIGHT
            ) {
                return color ?
                    `#${addAlpha(color, 0.8)}`
                    : 'rgba(0,0,0,0.8)';
            }

            // Adds a solid background to the bottom area
            if (this.layout === LAYOUT_TILT) {
                return color ? `#${color}` : '#000';
            }

            return color ? `#${color}` : 'transparent';
        },
        overlays() {
            return this.adData.overlay?.overlay_specs ?? [];
        }
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    }
};
</script>

<style lang="scss" scoped>
.product-container {
    height: 100%;
    width: 100%;
    position: absolute;
    &.type__collection {
        /* Allow space for navigation on collections */
        .product {
            height: 80%;
        }
    }
}

.product {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.layout__header {
    .product-content {
        display: flex;
        flex-direction: column-reverse;
    }
    .product-title {
        margin-top: 0;
        margin-bottom: 14px;
    }

    .product-sub-title {
        margin-top: 0;
        margin-bottom: 14px;
    }
}

.layout__fill_height {
    &.type__collection {
        .product {
            /* Override smaller content area */
            height: 100%;
        }
        .product-content {
            margin-bottom: 220px;
        }
    }
    .product {
        justify-content: flex-end
    }
    .product-content {
        margin-bottom: 140px;
    }
    .product-details {
        span {
            display: inline-block;
            padding: 0 10px;
            border-radius: 4px;
            background-color: var(--background-color);
            &::after {
                content: ''; /* Force line break */
                display: block;
            }
        }
    }
}

.layout__tilt {
    .product {
        justify-content: flex-end;
    }
    .product-content {
        height: 39%;
        &::before {
            content: '';
            position: relative;
            display: block;
            background-color: var(--background-color);
            width: 105%;
            height: 30px;
            transform: rotate(4deg);
            top: 12px;
            left: -10px;
        }
    }
    .product-details {
        background-color: var(--background-color);
        text-align: left;
    }
    .product-sub-title {
        margin-top: 6px;
    }
    .background-image {
        height: 65%;
    }
}

.product-content {
    position: relative;
    z-index: 5;
}
.product-details {
    text-align: center;
    padding: 0 30px;
    color: var(--text-color);
    span {
        display: block;
    }
}

.product-title {
    margin-top: 8px;
    font-size: 23px;
    font-weight: bold;
}

.product-sub-title {
    margin-top: 8px;
    font-size: 20px;
}

.product-image {
    width: 100%;
    display: inline-block;
    height: auto;
}

.background {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
</style>