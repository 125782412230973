var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay",class:[
        `type__${_vm.typeClassName}`,
        `ad-type__${_vm.adTypeClassName}`
    ],style:({
        '--opacity': _vm.opacity
    })},[(_vm.image && _vm.type === _vm.TYPE_FRAME)?_c('img',{staticClass:"overlay-frame-image",attrs:{"src":_vm.image}}):_c('div',{staticClass:"overlay-content-container"},[_c('div',{staticClass:"overlay-content-inner"},[_c('div',{staticClass:"overlay-content",class:[
                    `position__${_vm.position.toLowerCase()}`
                ]},[(_vm.image && _vm.type === _vm.TYPE_IMAGE)?_c('img',{staticClass:"overlay-image",attrs:{"src":_vm.image,"height":_vm.imageHeight,"width":_vm.imageWidth}}):_vm._e(),(_vm.text && _vm.type === _vm.TYPE_TEXT)?_c('span',{staticClass:"overlay-text",class:[
                        `shape__${_vm.textShape.toLowerCase()}`
                    ],style:({
                        backgroundColor: _vm.textShapeColor,
                        color: _vm.textColor,
                    })},[_c('span',[_vm._v(_vm._s(_vm.text))])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }