<template>
    <div
        class="overlay"
        :class="[
            `type__${typeClassName}`,
            `ad-type__${adTypeClassName}`
        ]"
        :style="{
            '--opacity': opacity
        }">
        <img
            v-if="image && type === TYPE_FRAME"
            :src="image"
            class="overlay-frame-image">
        <div
            v-else
            class="overlay-content-container">
            <!-- Position the container properly so we
            can place the inner zones relatively -->
            <div class="overlay-content-inner">
                <div
                    class="overlay-content"
                    :class="[
                        `position__${position.toLowerCase()}`
                    ]">
                    <img
                        v-if="image && type === TYPE_IMAGE"
                        :src="image"
                        :height="imageHeight"
                        :width="imageWidth"
                        class="overlay-image">
                    <span
                        v-if="text && type === TYPE_TEXT"
                        class="overlay-text"
                        :class="[
                            `shape__${textShape.toLowerCase()}`
                        ]"
                        :style="{
                            backgroundColor: textShapeColor,
                            color: textColor,
                        }">
                        <span>{{ text }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const TYPE_FRAME = 'FRAME';
const TYPE_IMAGE = 'IMAGE';
const TYPE_TEXT = 'TEXT';

const SHAPE_NONE = 'NONE';

const IMAGE_RATIO = 0.25;

export default {
    props: {
        value: {
            type: Object,
            required: true
        },
        adData: {
            type: Object,
            required: true
        },
        product: {
            type: Object,
            required: true
        },
        lightBackground: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            TYPE_FRAME,
            TYPE_IMAGE,
            TYPE_TEXT
        };
    },
    computed: {
        opacity() {
            return this.value.opacity ?
                (this.value.opacity / 100)
                : 1;
        },
        image() {
            return this.value.media?.file_url;
        },
        imageHeight() {
            return this.value.media?.image_metadata.height_px * IMAGE_RATIO;
        },
        imageWidth() {
            return this.value.media?.image_metadata.width_px * IMAGE_RATIO;
        },
        position() {
            return this.value.position;
        },
        type() {
            return this.value.type;
        },
        typeClassName() {
            return this.type ? `${this.type.toLowerCase()}` : '';
        },
        adType() {
            return this.adData?.creatives[0]?.media_type || this.adData?.media_items?.[0]?.media_type;
        },
        adTypeClassName() {
            return this.adType ? `${this.adType.toLowerCase()}` : '';
        },
        text() {
            // @todo Support strikethrough, etc
            return this.$formatNumber(
                this.product.price,
                this.$numberFormats.currency
            );
        },
        textShape() {
            return this.value.text_overlay_properties?.shape;
        },
        textShapeColor() {
            if (SHAPE_NONE === this.textShape) {
                return 'transparent';
            }

            const fallback = this.lightBackground ? '000' : 'fff';

            return `#${this.value.text_overlay_properties?.shape_color ?? fallback}`;
        },
        textColor() {
            // Use the opposite of the shape's color pattern
            let fallback = this.lightBackground ? 'fff' : '000';

            // If there's no shape follow the default logic
            if (SHAPE_NONE === this.textShape) {
                fallback = this.lightBackground ? '000' : 'fff';
            }

            return `#${this.value.text_overlay_properties?.text_color ?? fallback}`;
        }
    }
};
</script>

<style lang="scss">
.overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.type__text {
        z-index: 21;
    }
    &.type__image {
        z-index: 20;
    }
    &.ad-type__collection {
        .overlay-content-container {
            bottom: 27%;
        }
    }
}

.overlay-content-container {
    position: absolute;
    top: 14%;
    right: 2%;
    bottom: 16%;
    left: 2%;
}
.overlay-content-inner {
    position: relative;
    width: 100%;
    height: 100%;
}
.overlay-content {
    position: absolute;
    &.position__top_left {
        top: 0;
        left: 0;
    }
    &.position__top_middle {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    &.position__top_right {
        top: 0;
        right: 0;
    }
    &.position__middle_right {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
    &.position__bottom_right {
        bottom: 0;
        right: 0;
    }
    &.position__bottom_middle {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    &.position__bottom_left {
        bottom: 0;
        left: 0;
    }
    &.position__middle_left {
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
}
.overlay-frame-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: var(--opacity);
}
.overlay-text {
    font-weight: 600;
    font-size: 20px;
    padding: 4px 10px;
    &.shape__circle {
        font-size: 17px;
        border-radius: 50%;
        aspect-ratio: 1 / 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5px;
    }
    &.shape__pill {
        border-radius: 20px;
    }
}
</style>