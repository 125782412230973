<template>
    <div
        class="preview-container"
        @mouseover="$emit('show-play-buttons')"
        @mouseleave="$emit('hide-play-buttons')">
        <div class="preview-ui">
            <div class="top-elements">
                <div class="top-info">
                    <img
                        v-if="pageLogo"
                        class="logo"
                        :src="pageLogo">
                    <div
                        v-else
                        class="placeholder-logo">
                        YOUR
                        LOGO
                    </div>
                    <div class="top-title">
                        <div class="ml-2">
                            <div class="top-name">
                                {{ pageName }}
                            </div>
                            <div class="top-shop">
                                {{ sanitizedHeadlineText }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="top-dots">
                    <icon
                        class="top-dot"
                        size="5"
                        color="white"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="5"
                        color="white"
                        name="circle" />
                    <icon
                        class="top-dot"
                        size="5"
                        color="white"
                        name="circle" />
                </div>
            </div>
            <div class="preview-content">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { mapState, mapGetters } from 'vuex';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';
import {
    SNAPCHAT,
    headlineKey
} from '@/components/ad-studio/store/constants';

export default {
    name: 'PreviewContainer',
    components: {
        Icon,
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true
        }
    },
    data() {
        return {
            defaultLogoUrl: 'https://play-lh.googleusercontent.com/KxeSAjPTKliCErbivNiXrd6cTwfbqUJcbSRPe_IBVK_YmwckfMRS1VIHz-5cgT09yMo=w480-h960-rw',
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters(['dealerPrimaryLogo']),
        pageName() {
            return this.profileName || this.currentDealer.name || this.adData?.platform_specific_fields?.snapchat_page?.name;
        },
        pageLogo() {
            return this.profileLogo || this.dealerPrimaryLogo?.url || this.defaultLogoUrl;
        },
        profileName() {
            return this.adData?.platform_specific_fields?.brand_name ?? this.adData?.platform_specific_fields?.profile?.name;
        },
        profileLogo() {
            return this.adData?.platform_specific_fields?.profile?.picture;
        },
        creatives() {
            return this.adData?.creatives;
        },
        sanitizedHeadlineText() {
            const primaryText = this.adData?.headline || this.creatives[0]?.headline || this.adData?.media_items?.[0]?.headline;
            return primaryText?.slice(0, lengthValidatorConstants[headlineKey][SNAPCHAT].maxLength);
        }
    }
};
</script>

<style lang="scss" scoped>
$top_gutter: 80px;

.preview-container {
    position: relative;
    width: 375px;
    height: 740px;
    display: flex;
    flex-direction: column;
    background-color: $black;
    border-radius: 20px;
    overflow: hidden;
    color: $white;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.2);
    @media only screen and (max-width: 400px) {
        width: 320px;
        height: 780px;
    }
}
.preview-ui {
    position: relative;
    height: 100%;
}
.top-name {
    font-weight: 700;
    font-size: 14px;
    text-shadow: 2px 2px 10px #000000;
}
.top-shop {
    font-weight: 700;
    font-size: 10px;
    text-shadow: 2px 2px 10px #000000;
}
.top-elements {
    position: absolute;
    top: 15px;
    z-index: 9;
    left: 15px;
    right: 15px;
    display: flex;
    justify-content: space-between;
}
.top-dots {
    margin-left:3px;
    margin-top:5px;
    display: flex;
    flex-direction: column;
}
.top-dot {
    margin-top:3px;
}
.logo {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-top: 3px;
}
.top-info {
    display: flex;
}
.placeholder-logo{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border:1px solid white;
    color:white;
    background:black;
    text-align: center;
    font-weight:900;
    font-size: 6px;
    padding-top: 8px;
    line-height: 8px;
}
.top-title {
    display: flex;
    align-items: center;
}
.preview-content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    overflow: hidden;
    border-radius: 20px;
}
</style>
