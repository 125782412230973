var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-container",class:[
        _vm.layoutClassName,
        _vm.typeClassName
    ],style:({
        '--background-color': _vm.backgroundColor,
        '--text-color': _vm.textColor
    })},[_vm._l((_vm.overlays),function(overlay,index){return _c('overlay',{key:index,attrs:{"value":overlay,"ad-data":_vm.adData,"product":_vm.product,"light-background":_vm.lightBackground}})}),_c('div',{staticClass:"product"},[_c('div',{staticClass:"product-content"},[((
                    _vm.layout !== _vm.LAYOUT_FILL_HEIGHT &&
                    _vm.layout !== _vm.LAYOUT_TILT
                ))?_c('img',{staticClass:"product-image",attrs:{"src":_vm.primaryImage}}):_vm._e(),_c('div',{staticClass:"product-details"},[(_vm.title)?_c('span',{staticClass:"product-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.subTitle)?_c('span',{staticClass:"product-sub-title"},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]):_vm._e()])]),((
                _vm.layout !== _vm.LAYOUT_FILL_HEIGHT &&
                _vm.layout !== _vm.LAYOUT_TILT
            ))?_c('div',{staticClass:"background",style:({
                backgroundColor: _vm.backgroundColor
            })}):_vm._e(),((
                _vm.layout === _vm.LAYOUT_FIT ||
                _vm.layout === _vm.LAYOUT_FILL_HEIGHT ||
                _vm.layout === _vm.LAYOUT_TILT
            ))?_c('img',{staticClass:"background-image",attrs:{"src":_vm.primaryImage}}):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }